<template>
  <div id="dashboard-analytics">
    <!-- <empty-bots v-if="!hasBots && !isLoading"></empty-bots> -->
    <div class="dashboard-analytics-content">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="metrics-header">
            <h4 class="mb-4">{{ title }}</h4>

            <KonaAlert
              icon="ClockIcon"
              bg-color="rgba(var(--vs-warning), 0.15)"
              color="rgba(var(--vs-warning), 1)"
              v-show="updatedTime"
              size="small"
            >
              <span v-if="updatedTime">
                {{ lang.metrics.lastUpdate[languageSelected] }}
                {{ updatedTime | moment(dateFormat) }}
              </span>
              <a class="refresh" @click="refresh">
                {{ lang.metrics.refresh[languageSelected] }}
              </a>
            </KonaAlert>
          </div>

          <Filters
            type="card"
            :filter-model="filterModel"
            @init="filterChange"
            @on-apply="onApplyFilters"
          />
        </div>
      </div>

      <KonaTabs
        :tabsStyle="tabsStyle"
        v-if="$route.path !== '/knowledge' && $route.path !== '/dashboard'"
      >
        <KonaTab
          :name="lang.metrics.tabs.general[languageSelected]"
          icon="settings"
          :selected="true"
        >
          <draggable class="vx-row">
            <div
              v-for="(item, index) in reports"
              :key="`chart_${index}`"
              v-if="
                REPORTS_MODEL[item.reportType] &&
                  isSelectedReport(item) &&
                  isGenericChart(item)
              "
              class="vx-col mb-base cursor-move"
              :class="REPORTS_MODEL[item.reportType].classes"
            >
              <component
                :is="REPORTS_MODEL[item.reportType].component"
                v-bind="{
                  report: item,
                  services: services,
                  interval: interval,
                  platforms: platforms,
                  versions: versions
                }"
              />
            </div>
          </draggable>
        </KonaTab>
        <KonaTab
          :name="lang.metrics.tabs.contact[languageSelected]"
          icon="chat_bubble_outline"
        >
          <draggable class="vx-row">
            <div
              v-for="(item, index) in reports"
              :key="`chart_${index}`"
              v-if="
                REPORTS_MODEL[item.reportType] &&
                  isSelectedReport(item) &&
                  isContactCenterChart(item)
              "
              :class="
                `vx-col mb-base cursor-move ${REPORTS_MODEL[item.reportType].classes}`
              "
            >
              <component
                :is="REPORTS_MODEL[item.reportType].component"
                v-bind="{
                  report: item,
                  services: services,
                  interval: interval,
                  platforms: platforms,
                  versions: versions
                }"
              />
            </div>
          </draggable>
        </KonaTab>
      </KonaTabs>

      <draggable
        v-else-if="$route.path !== '/dashboard'"
        class="vx-row"
        :class="{ 'knowledge-wrapper': $route.path === '/knowledge' }"
      >
        <div
          v-for="(item, index) in reports"
          :key="`chart_${index}`"
          class="vx-col mb-base cursor-move"
          :class="REPORTS_MODEL[item.reportType].classes"
          v-if="REPORTS_MODEL[item.reportType] && isSelectedReport(item)"
        >
          <component
            :is="REPORTS_MODEL[item.reportType].component"
            v-bind="{
              report: item,
              services: services,
              interval: interval,
              platforms: platforms,
              versions: versions
            }"
          />
        </div>
      </draggable>
      <draggable class="vx-row" v-else>
        <div
          v-for="(item, index) in reports"
          :key="`chart_${index}`"
          class="vx-col w-full sm:w-1/2 md:w-1/2"
          :class="REPORTS_MODEL[item.reportType].classes"
          v-if="REPORTS_MODEL[item.reportType] && isSelectedReport(item)"
        >
          <component
            :is="REPORTS_MODEL[item.reportType].component"
            v-bind="{
              report: item,
              services: services,
              interval: interval,
              platforms: platforms,
              versions: versions
            }"
          />
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import konecta from '@/services/konecta'
import { METRICS_REPORTS } from '@/constants/constants'

const REPORTS_MODEL = {
  histogram_by_platform_v1: {
    component: 'HistogramByPlatformV1',
    classes: 'md:w-1/2'
  },
  funnel_by_platform_v1: {
    component: 'FunnelByPlatformV1',
    classes: 'md:w-1/2'
  },
  line_chart_v1: {
    component: 'LineChartV1',
    classes: 'md:w-1/2'
  },
  validation_pie_v1: {
    component: 'ValidationPieV1',
    classes: 'md:w-1/2'
  },
  columns_v1: {
    component: 'ColumnsV1',
    classes: 'md:w-1/2'
  },
  pie_v1: {
    component: 'PieV1',
    classes: 'md:w-1/2'
  },
  table_v1: {
    component: 'TableV1',
    classes: 'md:w-1/2'
  },
  takeovers_table_v1: {
    component: 'TakeoversTableV1',
    classes: 'md:w-1/2'
  },
  takeovers_histogram_v1: {
    component: 'TakoversHistogramV1',
    classes: 'md:w-1/2 lg:w-2/3 xl:w-2/3'
  },
  table_duration_v1: {
    component: 'TableDurationV1',
    classes: 'md:w-1/2'
  },
  tags_table_v1: {
    component: 'TagsTableV1',
    classes: 'md:w-1/2'
  },
  cards_v1: {
    component: 'CardsV1',
    classes: 'md:w-1/2 lg:w-1/3 xl:w-1/3'
  },
  cards_v2: {
    component: 'CardsV2',
    classes: 'md:w-1/2 lg:w-1/2 xl:w-1/2'
  },
  session_percentage_cards_v1: {
    component: 'SessionPercentageCardsV1',
    classes: 'md:w-1/2 lg:w-1/3 xl:w-1/3'
  },
  card_line_v1: {
    component: 'CardLineV1',
    classes: 'md:w-1/2 lg:w-1/3 xl:w-1/3'
  }
}

// const ETL_POLLING_TIME = 60000 * 5 // 5 minutos

export default {
  components: {
    EmptyBots: () => import('./EmptyBots'),
    draggable: () => import('vuedraggable'),
    HistogramByPlatformV1: () =>
      import('./charts/histogram_by_platform_v1/Main'),
    FunnelByPlatformV1: () => import('./charts/funnel_by_platform_v1/Main'),
    CardsV1: () => import('./charts/cards_v1/Main'),
    CardsV2: () => import('./charts/cards_v2/Main'),
    LineChartV1: () => import('./charts/line_chart_v1/Main'),
    ColumnsV1: () => import('./charts/columns_v1/Main'),
    ValidationPieV1: () => import('./charts/validations_pie_v1/Main'),
    SessionPercentageCardsV1: () =>
      import('./charts/session_percentage_cards_v1/Main'),
    CardLineV1: () => import('./charts/card_line_v1/Main'),
    PieV1: () => import('./charts/pie_v1/Main'),
    TableV1: () => import('./charts/table_v1/Main'),
    TableDurationV1: () => import('./charts/table_duration_v1/Main'),
    TakeoversTableV1: () => import('./charts/takeovers_table_v1/Main'),
    TagsTableV1: () => import('./charts/tags_table_v1/Main'),
    Filters: () => import('./filters/Filters.vue'),
    BotFilter: () => import('./filters/components/BotFilter.vue'),
    KonaTabs: () => import('../../../components/kona-tabs/KonaTabs.vue'),
    KonaTab: () => import('../../../components/kona-tabs/KonaTab.vue'),
    KonaAlert: () => import('@/components/KonaAlert.vue'),
    TakoversHistogramV1: () => import('./charts/takeovers_histogram_v1/Main')
  },
  data() {
    return {
      REPORTS_MODEL,
      // datepickerPopup: false,
      isLoading: false,
      key: new Date(),

      hasBots: false,

      reports: [],
      // reportsLeftRight: [],
      versions: [],
      bots: [],
      title: '',
      value: [],
      options: [],
      filterModel: [],
      services: [],
      interval: {},
      filteredReports: [],
      platforms: [],
      updatedTime: null,
      reportOptions: null,
      filterKey: 0
      // intervalFn: null,
      // intervalDisplay: null,
      // displayUpdatedTime: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('integrations', ['botChannels']),
    tabsStyle() {
      return `
        width: 100%;
        background: white;
        justify-content: center;
      `
    },
    dateFormat() {
      if (this.languageSelected === 'es') {
        return 'DD-MM-YYYY, HH:mm'
      }

      return 'YYYY-MM-DD, h:mm A'
    }
  },
  watch: {
    '$store.state.languageSelected'() {
      this.displayUpdatedTime =
        this.updatedTime && this.$moment(this.updatedTime).fromNow()
    },
    $route() {
      // navigation metrics <-> knowledge
      this.filterKey += 1
      this.reportOptions = this.parseReportOptions()
      this.setFilterModel()
      // await this.filterChange({
      //   type: 'report',
      //   value: this.reportOptions
      // })
    }
  },
  methods: {
    isSelectedReport(item) {
      return this.filteredReports.includes(item.name[1].text)
    },
    async getBots() {
      try {
        this.$vs.loading()
        this.isLoading = true
        const response = await konecta.get(`/bot/active-version`)

        this.bots = response.data.map(element => ({
          _id: element._id,
          serviceId: element.service._id,
          name: element.service.name,
          _updatedAt:
            element.service._updatedAt ||
            element._updatedAt ||
            element.service._createdAt ||
            element._createdAt
        }))
        // this.hasBots = this.bots.length > 0

        if (
          !this.$route.query.services ||
          this.$route.query.services.length === 0
        ) {
          this.options = this.bots.map(element => ({
            name: element.name,
            code: element.serviceId
          }))
          // this.value = this.options
        } else {
          const services = this.bots.map(element => element.serviceId)
          const filtered = (typeof this.$route.query.services === 'string'
            ? [this.$route.query.services]
            : this.$route.query.services
          ).filter(element => {
            return services.indexOf(element) > -1
          })
          this.options = []
          this.value = []
          this.bots.forEach(element => {
            if (filtered.indexOf(element.serviceId) > -1) {
              this.value.push({
                name: element.name,
                code: element.serviceId
              })
            }
            this.options.push({
              name: element.name,
              code: element.serviceId
            })
          })
        }
        this.$vs.loading.close()
      } catch (error) {
        // TODO: handle message
        this.$vs.notify({
          title: 'Error',
          text: 'There was an error loading report services',
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    isGenericChart(item) {
      return item.reportSection && item.reportSection === 'generic'
    },
    isContactCenterChart(item) {
      return !item.reportSection || item.reportSection === 'contact_center'
    },
    parseRange(values) {
      const from = values[0]
      const to = values[1]

      return { from, to }
    },
    onApplyFilters(filters) {
      const filtersTypes = Object.keys(filters)
      filtersTypes.forEach(filter => this.filterChange(filters[filter]))
    },
    filterChange(filter) {
      switch (filter.type) {
        case 'bot':
          this.services = filter.value.map(bot => bot.service)
          this.versions = filter.value.map(bot => bot._id)
          this.$router.push({
            query: { services: this.services, versions: this.versions }
          })
          break
        case 'date':
          if (filter.value[0] && filter.value[1]) {
            this.interval = this.parseRange(filter.value)
          }
          break
        case 'report':
          this.filteredReports = filter.value.map(report => report.name)
          break
        case 'platform':
          this.platforms = filter.value.map(platform => platform)
          break
        default:
          break
      }

      this.getLastUpdate()
    },
    async getReports() {
      // let reportsQuery = ''
      // this.filterReports.forEach(
      //   report => (reportsQuery += `&where[reportType][$in]=${report}`)
      // )
      let reportsQuery = ''
      METRICS_REPORTS.forEach(
        report => (reportsQuery += `&where[reportType][$in]=${report}`)
      )
      try {
        const response = await konecta.get(
          '/report?select[reportSection]=true&select[name]=true&select[description]=true&select[reportType]=true&select[_extra]=true' +
            reportsQuery
        )
        if (this.$route.path === '/dashboard') {
          response.data = response.data.filter(
            r => r._extra != null && r._extra.order != null
          )
          this.reports = response.data.sort(function(a, b) {
            return a._extra.order - b._extra.order
          })
        } else {
          this.reports = response.data
        }
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          title: 'Error',
          text: 'There was an error loading report services',
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    weekInterval() {
      const to = new Date()
      let from = new Date(to.getTime())
      from.setDate(from.getDate() - 7)
      return {
        from: new Date(from.setHours(0, 0, 0, 0)),
        to: new Date(to.setHours(23, 59, 59, 999))
      }
    },
    parseReportOptions() {
      let reportOptions = []
      // TODO: map
      this.reports.forEach(report => {
        reportOptions.push({
          name: report.name[1].text,
          es: report.name[0].text,
          en: report.name[1].text,
          pt: report.name[2].text
        })
      })

      const reportFilter = this.$route.meta.reportsFilter
      if (reportFilter) {
        reportOptions = reportOptions.filter(report =>
          reportFilter.includes(report.name)
        )
      }
      return reportOptions
    },
    setFilterModel() {
      if (this.$route.path === '/dashboard') {
        try {
          this.filterModel = [
            {
              name: 'bots',
              component: 'BotFilter',
              width: 4,
              props: {
                allowEmpty: false,
                startAll: true
              }
            },
            {
              name: 'platform',
              component: 'PlatformFilter',
              width: 4,
              props: {
                multipleSelect: true,
                value: this.platforms,
                key: this.filterKey,
                startAll: true
              }
            }
          ]
          // const botMap = this.options.map(bot => bot.code)
          // this.services = botMap
        } catch (error) {
          // TODO: handle message
          this.$vs.notify({
            title: 'Error',
            text: 'There was an error loading etl',
            color: 'danger',
            position: 'top-right'
          })
        }

        this.filteredReports = this.reportOptions.map(report => report.name)
      } else {
        this.filterModel = [
          {
            name: 'bots',
            component: 'BotFilter',
            width: 4,
            props: {
              allowEmpty: true,
              startAll: true
            }
          },
          {
            name: 'dates',
            component: 'DateRangeFilter',
            width: 4,
            props: {
              value: this.interval
            }
          },
          {
            name: 'platform',
            component: 'PlatformFilter',
            width: 4,
            props: {
              multipleSelect: true,
              value: this.platforms,
              key: this.filterKey,
              startAll: true
            }
          },
          {
            name: 'reports',
            component: 'ReportFilter',
            width: 4,
            props: {
              options: this.reportOptions,
              key: this.filterKey
            }
          }
        ]
      }
    },
    // async getEtl() {
    //   try {
    //     const response = await konecta.get('/etl?limit=1')
    //     this.updatedTime = response.data[0]._createdAt

    //     this.intervalFn = setInterval(
    //       async function() {
    //         const response = await konecta.get('/etl?limit=1')
    //         this.updatedTime = response.data[0]._createdAt
    //       }.bind(this),
    //       ETL_POLLING_TIME
    //     )
    //   } catch (error) {
    //     // TODO: handle message
    //     this.$vs.notify({
    //       title: 'Error',
    //       text: 'There was an error loading etl',
    //       color: 'danger',
    //       position: 'top-right'
    //     })
    //   }
    // },
    async getLastUpdate() {
      try {
        const response = await konecta.get('/etl?limit=1')
        this.updatedTime = response.data[0]._createdAt
      } catch (error) {
        this.$vs.notify({
          title: 'Error',
          text: 'There was an error loading etl',
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    async refresh() {
      if (this.$route.path === '/dashboard') {
        this.title = this.lang.metrics.dashboard[this.languageSelected]
      } else {
        this.title = this.lang.metrics.title[this.languageSelected]
      }
      const auxInterval = this.interval
      this.interval = this.weekInterval()
      await this.$nextTick()
      this.interval = auxInterval
      await this.getLastUpdate()
    }
    // getUpdatedTime() {
    //   this.displayUpdatedTime =
    //     this.updatedTime && this.$moment(this.updatedTime).fromNow()

    //   this.intervalDisplay = setInterval(
    //     function() {
    //       this.displayUpdatedTime =
    //         this.updatedTime && this.$moment(this.updatedTime).fromNow()
    //     }.bind(this),
    //     60000 // display updated each minute
    //   )
    // }
  },
  async mounted() {
    // set default interval
    this.interval = this.weekInterval()

    await this.getReports()
    this.reportOptions = this.parseReportOptions()
    await this.getBots()
    this.setFilterModel()
    if (this.$route.path === '/dashboard') {
      this.title = this.lang.metrics.dashboard[this.languageSelected]
    } else if (this.$route.path === '/knowledge') {
      this.title = this.lang.metrics.knowledge[this.languageSelected]
    } else {
      this.title = this.lang.metrics.title[this.languageSelected]
    }
    // await this.getEtl()
    await this.getLastUpdate()
    // this.getUpdatedTime()
  }
  // beforeDestroy() {
  //   clearInterval(this.intervalFn)
  //   clearInterval(this.intervalDisplay)
  // }
}
</script>

<style lang="scss">
.metrics-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .kona-alert {
    margin-top: 0;

    .refresh {
      margin-left: 10px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
h6 {
  margin-bottom: 1rem;
}
.apexcharts-legend {
  border: none !important;
}
.knowledge-wrapper {
  margin-top: 20px;
}
.vx-card__body {
  .header {
    .row {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
